// Anything exported from this file is importable by other in-browser modules.
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';
import { logoutUser, msalConfig, msalInstance } from './auth';

// config based on env from webpack
const storeConfig = require('storeConfig'); 

interface ProgramDropDownOption {
  name: string;
  value: string;
}

interface ChangeRouteOption {
  activePage: string;
  isFromSideBar?: boolean;
}

export const activeRoute$ = new ReplaySubject<null | ChangeRouteOption>(null);
export const authState$ = new ReplaySubject<null | AuthenticationResult>();
export const selectedProgram$ = new ReplaySubject<ProgramDropDownOption | null>();
export const onOrganizationChange$ = new BehaviorSubject<Array<string>>([]);

/**
 * Used to toggle sideapp from navapp, listen to this 
 * subject to get the current status of sidebar visibilty
 */
export const showSidebar$ = new BehaviorSubject<boolean>(true);

/* 
  * #R2-421 Fixed the changeActiveRoute bug
  * Declared a new interface to handle redirection from sidebar or not
*/
export function changeActiveRoute(option: ChangeRouteOption) {
  activeRoute$.next(option);
}

export async function checkLogin() {
  //  return await checkLoginStatus(authState$);
  return;
}

export async function logout() {
  return await logoutUser();
}

export function setSelectedProgram(program: ProgramDropDownOption) {
  selectedProgram$.next(program);
}

export function getMsalConfig() {
  return msalConfig;
}

export function getMsalInstance() {
  return msalInstance;
}

export function toggleSidebar() {
  showSidebar$.next(!showSidebar$.value);
}

export function hideSidebar() {
  showSidebar$.next(false);
}

export function showSidebar() {
  showSidebar$.next(true);
}

export function changeActiveOrganization(organization: Array<string>) {
  onOrganizationChange$.next(organization);
}

// Initialize Sentry
Sentry.init({
  dsn: storeConfig.SENTRY_DSN,
  environment: storeConfig.ENVIRONMENT,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
    Sentry.moduleMetadataIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/(dev-|stg-)?vantage\.betteroutcomes\.health/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // enable debug to log Sentry events in local
  debug: false,
  attachStacktrace: true,
});

// Expose Sentry globally
window['Sentry'] = Sentry;