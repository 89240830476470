import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PublicClientApplication,
  SilentRequest
} from '@azure/msal-browser';
import {
  AuthenticationResult,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { ReplaySubject, interval } from 'rxjs';

const storeConfig = require('storeConfig'); // config based on env from webpack

const authServiceProxyApi = storeConfig.AUTH_SERVICE_PROXY_API;

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: storeConfig.MSAL_CLIENT_ID,
    redirectUri: storeConfig.MSAL_REDIRECT_URI,
    authority: storeConfig.MSAL_AUTHORITY,
    knownAuthorities: [storeConfig.MSAL_AUTHORITY_DOMAIN],
    cloudDiscoveryMetadata: ''
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: true,
    },
  },
};

const silentRequest = {
  scopes: []
};

export const msalInstance = new PublicClientApplication(msalConfig);

export async function checkLoginStatus(
  authState: ReplaySubject<null | AuthenticationResult | any>
) {
  try {
    console.log('msalInstance::::::::::' + msalInstance.getAllAccounts().length);
    const result: AuthenticationResult = await msalInstance.ssoSilent(silentRequest);
    if (result) {
      authState.next(result);
    }
  } catch (error: any) {
    if (error instanceof InteractionRequiredAuthError) {
      console.log('No user signed in !');
    } else {
      console.log('Auth Error : ', error);
    }
    authState.next(null);
  }
}

export async function logoutUser() {
  const [ account ] = msalInstance.getAllAccounts();
  const tokenResponse = await msalInstance.acquireTokenSilent({ account } as SilentRequest);
  await fetch(`${authServiceProxyApi}/logout`, {
    headers: {
      Authorization: `Bearer ${tokenResponse.idToken}`
    }
  });
  return await msalInstance.logoutRedirect({
    postLogoutRedirectUri: '/auth'
  });
}

/**
 * R2-1263
 * Context: FHIR API requests after 5 minutes when staying on the same page (pages with auto refresh enabled every 2 minutes: dashboard, patient summary)
 * is causing 401 error. This timer will trigger a forced token refresh every 5 minutes acquiring new access tokens for subsequent API calls
 */
interval(5 * 60 * 1000)
  .subscribe(() => {
    console.log('Refreshing MSAL access token...');
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    msalInstance.acquireTokenSilent({ account } as SilentRequest)
      .then(() => {
        console.log('MSAL access token refreshed');
      })
      .catch((error) => {
        console.log('MSAL access token refresh failed', JSON.stringify(error));
      });
  });